















































import { Component, Prop, Vue } from "vue-property-decorator";
import modeloModule from "@/store/modules/modelo-module";
import { modelo } from "@/shared/dtos/platillas-modelos/modelo";
import { RouterNames } from "@/router/routernames";
import { UtilsEncript } from "@/utils/utils-encript";
@Component({
  $_veeValidate: { validator: "new" },
  components: {},
})
export default class addplatilla extends Vue {
  @Prop({ default: 0 }) id!: number;
  @Prop({ default: false }) update!: boolean;

  public platilla: modelo = new modelo();
  created() {
    if (this.update) {
      modeloModule.getmodelo(this.id).then((x) => {
        this.platilla = x;
      });
    }
  }
  public Aceptar() {
    this.$validator.validateAll().then((allOk) => {
      if (allOk) {
        if (!this.update) {
          modeloModule.guardarmodelo(this.platilla).then((x: any) => {
            this.$router.push({
              name: RouterNames.dieta,
              query: {
                id_dieta: UtilsEncript.Encriptar(x.id_dieta.toString()),
                id_paciente: UtilsEncript.Encriptar("-1"),
              },
            });
          });
        } else {
          modeloModule
            .modificarmodelo(this.platilla)
            .then(() => this.$emit("close"));
        }
      }
    });
  }
  public close() {
    this.$emit("close");
  }
}
